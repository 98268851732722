.home__section {
  background-image: url('../../images/bg-portfolio-home-mobile-v.svg');
  background-repeat: no-repeat;
  background-position: right top;
  background-size: contain;

  .section__container-home {
    position: relative;
  }

  .icon__mouse {
    opacity: 0;
    animation: fadeOut 1000ms ease-in-out 0ms forwards;
  }

  .home__developer {
    height: 25rem;
    width: 16rem;

    @include display-column-center-row-start;

    position: absolute;
    top: 75%;
    left: 15%;
    transform: translateY(-75%);

    > div {
      width: 100%;
      height: 50%;
      overflow: hidden;
      position: absolute;
      padding-left: 0.25rem;

      > div,
      .list {
        padding: 1rem 0;
        position: absolute;
      }
    }

    .text__top {
      top: 0;

      >:last-child {
        margin-bottom: 0;
      }

      .title__home {
        @include font-accent;

        font-size: 2.5rem;
        color: $purple-dark;
        margin-bottom: 0.5rem;
      }

      .subtitle__home {
        font-size: 1.5rem;
        font-weight: bolder;
      }

      > div {
        opacity: 0;
        animation: fadeOut 1000ms ease-in-out 0ms forwards;
      }
    }

    .text__bottom {
      bottom: 0;

      > .list {
        opacity: 0;
        animation: fadeOut 1000ms ease-in-out 0ms forwards;

        li {
          &:first-child {
            padding-left: 0;
          }
        }
      }
    }
  }

  .home__messages {
    width: 70%;
    height: 5rem;
    display: block;
    overflow: hidden;
    text-align: center;
    position: absolute;
    left: 15%;
    bottom: 15%;
    opacity: 0;
    animation: fadeOut 1000ms ease-in-out 0ms forwards;

    @include css_slider(3);
  }

  @include mq-tablet {
    background-image: url('../../images/bg-portfolio-home-tablet-v.svg');
    background-size: cover;

    .home__developer {
      height: 30rem;
      width: 18rem;
      top: 60%;
      transform: translateY(-60%);

      .text__top {
        .title__home {
          font-size: 3rem;
        }

        .subtitle__home {
          font-size: 1.75rem;
        }
      }
    }

    .home__messages {
      font-size: 1.25rem;
      bottom: 15%;
    }
  }

  @include mq-desktop {
    background-image: url('../../images/bg-portfolio-home-desktop.svg');
    background-size: cover;
  }
}

#home.home__animation {
  .text__top {
    > div {
      bottom: 0;
      transform: translateY(100%);
      animation: showTopTextMobile 2000ms cubic-bezier(0.645, 0.045, 0.355, 1) 1000ms forwards;
    }

    &::after {
      display: table;
      content: "";
      height: 0.5px;
      width: calc(4 * 3rem);
      background-color: $coral-dark;
      position: absolute;
      bottom: 0;
      left: 0;
      animation: revealBar 1000ms cubic-bezier(0.645, 0.045, 0.355, 1) 0ms forwards;
    }
  }

  .text__bottom {
    > .list {
      top: 0;
      transform: translateY(-100%);
      animation: showBottomText 1000ms cubic-bezier(0.645, 0.045, 0.355, 1) 2000ms forwards;
    }
  }

  .home__messages {
    animation: translateToRight 1000ms cubic-bezier(0.645, 0.045, 0.355, 1) 2500ms forwards;
  }

  .icon__mouse {
    animation: translateToUp 1000ms cubic-bezier(0.645, 0.045, 0.355, 1) 3000ms forwards;
  }
}

@keyframes showTopTextMobile {
  0% { opacity: 1; }
  0% { transform: translateY(100%); }

  40%,
  60% { transform: translateY(30%); }
  100% { opacity: 1; }
  100% { transform: translateY(0); }
}

@keyframes showTopTextDesktop {
  0% { transform: translateY(100%); }

  40%,
  60% { transform: translateY(50%); }
  100% { transform: translateY(0); }
}

@keyframes showBottomText {
  0% { opacity: 1; }
  0% { transform: translateY(-100%); }
  100% { opacity: 1; }
  100% { transform: translateY(0); }
}

@keyframes revealBar {
  0% { width: 0; }
  100% { width: calc(4 * 3rem); }
}
