.app-navbar {
  width: 100%;
  height: 100vh;
  background-color: $base;
  display: none;

  .menu__list {
    width: 100%;
    padding: 1rem;
    opacity: 0;

    @include display-column-center-row-center;

    .list__item {
      padding: 1rem 0;
      font-size: 2rem;

      @include display-row-center-col-center;
    }

    .list__item-link.active {
      color: $coral-dark;
      font-weight: bold;
      position: relative;
    }
  }

  @include mq-desktop {
    width: 10%;
    display: flex;
    background-color: transparent;
    position: fixed;
    top: 0;
    right: 0;
    z-index: -1;

    .menu__list {
      padding-right: 2rem;
      opacity: 1;
      align-items: flex-end;
      position: relative;

      .list__item {
        font-size: 0.8rem;
        position: relative;

        .list__item-link.active::after {
          display: table;
          content: "";
          height: 1px;
          width: 100%;
          background-color: $coral-dark;
          position: absolute;
          bottom: 0;
          right: 0;
          z-index: 1;
          animation: withBarToLeft 1000ms ease-in-out;
        }
      }
    }
  }
}

.navbar__modal-open {
  width: 100%;
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  z-index: -1;

  .menu__list {
    .active::before,
    .active::after {
      display: table;
      content: "";
      height: 1px;
      width: 2rem;
      background-color: $coral-dark;
      transition: transform 500ms ease;
      position: absolute;
      top: 50%;
      z-index: 2;
    }

    .active::before {
      left: -2.5rem;
    }

    .active::after {
      right: -2.5rem;
    }
  }
}
