@font-face {
  font-family: Saudagar;
  src: url('../../fonts/Saudagar.ttf') format('truetype');
}

@font-face {
  font-family: Galgine;
  src: url('../../fonts/Galgine.otf') format('opentype');
}

@mixin font-base ($fs: 1rem, $fw: bold, $lh: normal) {
  font-family: Saudagar, sans-serif;
  font-size: $fs;
  font-weight: $fw;
  line-height: $lh;
  letter-spacing: 0.05rem;
}

@mixin font-title ($fs: 1rem, $fw: bold, $lh: 1.2) {
  font-family: Saudagar, sans-serif;
  font-weight: $fw;
  line-height: $lh;
}

@mixin font-accent ($fs: 1rem, $fw: bold, $lh: 1) {
  font-family: Galgine, sans-serif;
  font-size: $fs;
  font-weight: $fw;
  line-height: $lh;
}
