.admin__header {
  background-color: $base;

  .brand {
    text-transform: uppercase;
  }
}

.admin__section {
  width: 100%;

  .section__submenu {
    margin-bottom: 2rem;
  }

  .records__list {
    width: 100%;

    @include display-column-start-row-start;

    > .list__item {
      width: 100%;
      border-top: 1px solid $purple-dark;

      @include display-column-start-row-start;

      > * {
        width: 100%;
        padding: 1rem 0;

        @include display-column-start-row-start;
      }

      .item__group {
        width: 100%;
        padding: 1rem 0;
      }

      .item__group-buttons {
        @include display-row-start-col-center;

        justify-content: space-evenly;
      }

      .list__item-title {
        h2 {
          color: $purple-dark;
          text-transform: uppercase;
        }
      }

      .list__item-tool-icon {
        height: 4rem;

        img {
          height: 100%;
          width: auto;
        }
      }

      .list__item-details {
        @include display-column-start-row-start;

        .item__column {
          width: 100%;
        }

        .item__column-images {
          img {
            width: 300px;
            height: auto;
          }
        }
      }
    }
  }
}

.label-highlighted {
  font-weight: bold;
  cursor: pointer;
  position: relative;

  .input-highlighted {
    margin-left: 1rem;
    opacity: 0;
  }

  .checkbox-mock::before,
  .checkbox-mock::after {
    display: inline-block;
    position: absolute;
    top: 0;
    right: -0.15rem;
    z-index: 1;
  }

  .checkbox-mock::before {
    content: "";
    height: 1rem;
    width: 1rem;
    border: 1px solid $purple-dark;
  }

  .checkbox-mock::after {
    content: none;
    height: 6px;
    width: 10px;
    border-left: 2px solid $coral-dark;
    border-bottom: 2px solid $coral-dark;
    transform: rotate(-45deg);
    top: 3px;
    right: 0;
  }

  .input-highlighted:checked + .checkbox-mock::after {
    content: "";
  }
}

.color-sample {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  margin-right: 1rem;
}

@include mq-tablet {
  .admin__section {
    .records__list {
      .list__item {
        .list__item-admin {
          @include display-row-start-col-center;
        }
      }
    }
  }
}

@include mq-desktop {
  .admin__section {
    .records__list {
      .list__item {
        .list__item-details {
          @include display-row-start-col-start;

          .item__column {
            padding: 0.5rem;
          }

          .item__group-buttons {
            @include display-column-start-row-start;

            .btn {
              margin-bottom: 1rem;
            }
          }
        }
      }
    }
  }
}
