.app-header {
  width: 100%;
  height: 5rem;
  padding: 0.5rem 5%;
  background-color: transparent;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 6;

  @include display-column-center-row-center;

  .header__top {
    width: 100%;

    @include display-row-center-col-center;

    justify-content: space-between;
  }

  .brand {
    font-size: 1.5rem;
    font-weight: bolder;
    color: $coral-dark;

    @include display-row-center-col-center;

    svg {
      height: 3rem;
      width: auto;
      fill: $coral-dark;
      stroke: none;
    }

    img {
      height: 100%;
      width: auto;
    }
  }

  .menu__button {
    width: auto;
    height: 25px;
    background-color: transparent;
    border: none;
    cursor: pointer;

    @include display-row-center-col-center;

    .menu__button-bar {
      width: 2rem;
      height: 3px;
      background-color: $purple-dark;
      position: relative;
      transition: transform 750ms ease, background-color 250ms ease-in-out;
    }

    .menu__button-bar::before,
    .menu__button-bar::after {
      display: table;
      content: "";
      height: 3px;
      width: 100%;
      position: absolute;
      background-color: $purple-dark;
      transition: transform 750ms ease, background-color 250ms ease-in-out;
    }

    .menu__button-bar::before {
      top: -8px;
    }

    .menu__button-bar::after {
      top: 8px;
    }
  }

  .menu__button-modal {
    .menu__button-bar {
      transform: rotate(45deg);
    }

    .menu__button-bar::before {
      top: 0;
      transform: rotate(90deg);
    }

    .menu__button-bar::after {
      top: 0;
      transform: rotate(90deg);
    }
  }

  @include mq-tablet {
    padding: 1.5rem 5%;
    height: 7rem;

    .brand {
      svg {
        height: 4rem;
      }
    }

    .menu__button {
      height: 33px;

      .menu__button-bar {
        width: 3rem;
      }
    }
  }

  @include mq-desktop {
    .menu__button {
      display: none;
    }

    .menu__button:hover {
      .menu__button-bar,
      .menu__button-bar::before,
      .menu__button-bar::after {
        background-color: $coral-dark;
      }
    }
  }
}

.header__admin {
  @include display-row-center-col-center;

  justify-content: space-between;

  .menu__list {
    @include display-row-start-col-center;
  }
}
