.modal__box {
  width: 100%;
  height: 100%;
  background-color: $base;
  transition: background-color 250ms ease-in;

  @include display-row-center-col-center;

  display: none;

  .modal__container {
    width: 100%;
    height: 100vh;
    padding: 1rem;

    @include display-column-start-row-center;
  }

  .modal__header {
    width: 100%;
    margin-bottom: 1rem;

    @include display-row-end-col-center;
  }

  .modal__content {
    width: 100%;
    height: auto;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: rgba($yellow-dark, 0.5);

    &::-webkit-scrollbar-track {
      background-color: rgba($yellow-dark, 0.2);
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba($yellow-dark, 0.3);
      border-radius: 10px;
    }

    &::-webkit-scrollbar {
      width: 0.25rem;
    }

    h2 {
      color: $coral-dark;
      margin-bottom: 2rem;
    }

    form {
      max-width: 48rem;
      gap: 1rem;

      label {
        color: $coral-dark;
      }
    }
  }

  @include mq-tablet {
    .modal__container {
      padding: 2rem;
    }
  }
}

.modal__open {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 7;
}
