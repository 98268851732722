$base: #fff5da;
$purple-dark: #2f1728;
$purple-light: #402952;
$coral-dark: #ff335f;
$coral-light: #ff6b71;
$coral-pale: #ffaead;
$yellow-dark: #d5873f;
$yellow-medium: #f5b539;
$yellow-light: #ffc94b;
$green-dark: #ac9f40;
$green-light: #f3e689;
$gray-dark: #908f90;
$white: #fff;
