@mixin display-row-start-col-start {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

@mixin display-row-start-col-center {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

@mixin display-row-center-col-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

@mixin display-row-end-col-center {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

@mixin display-column-start-row-start {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

@mixin display-column-start-row-center {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

@mixin display-column-center-row-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@mixin display-column-center-row-start {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

@mixin display-column-end-row-center {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

@mixin mq-tablet ($min-width: 48rem) {
  @media (min-width: $min-width) {
    @content;
  }
}

@mixin mq-desktop ($min-width: 61.5rem) {
  @media (min-width: $min-width) {
    @content;
  }
}

@mixin css_slider($slides_no, $slide_duration: 5, $slide_fade_duration: 0.5, $animation_name: 'slides_animation') {
  // Calculate total animation time
  $animation_duration: $slides_no * $slide_duration;

  // Convert seconds to percentage duration for one slide

  $slide_percentage_duration: calc(100% / $slides_no);

  // Calculate fade time in percentage
  $fade_percentage_duration: calc((100% * $slide_fade_duration) / $animation_duration);

  // Set the keyframes
  @keyframes #{$animation_name} {
    0% { opacity: 0; }
    #{$fade_percentage_duration} { opacity: 1; }
    #{$slide_percentage_duration} { opacity: 1; }
    #{$slide_percentage_duration + $fade_percentage_duration} { opacity: 0; }
    100% { opacity: 0; }
  }

  .slides__container {
    min-width: 100%;
    min-height: 100%;
    display: block;
    position: relative;

    .slide {
      width: 100%;
      height: 100%;
      max-height: 100%;
      max-width: 100%;
      opacity: 0;

      @include display-row-center-col-center;

      position: absolute;
      left: 0;
      top: 0;
      animation-name: #{$animation_name};
      animation-duration: #{$animation_duration}s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
      animation-direction: normal;
      animation-delay: 0s;
      animation-play-state: running;
      animation-fill-mode: forwards;
    }

    @for $i from 1 through $slides_no {
      .slide:nth-child(#{$i}) {
        animation-delay: #{($i - 1) * $slide_duration}s;
      }
    }
  }
}
