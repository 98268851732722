.works__section {
  background-image: url('../../images/bg-portfolio-gral-desktop.svg');
  background-repeat: no-repeat;
  background-position: left top;
  background-size: cover;

  .section__container {
    padding-bottom: 8%;
  }

  .image__box {
    height: 65%;
    position: relative;
  }

  .image__box-shadow {
    width: 100%;
    height: 100%;
    background-color: rgba($yellow-light, 0.25);
    position: absolute;
    top: 0;
    left: 0;
  }

  .description__box {
    width: 100%;
    height: auto;
    padding-left: 0.5rem;
    position: absolute;
    top: 80%;
    left: 0;
    transform: translateY(-80%);

    @include display-column-start-row-start;

    .title {
      margin-bottom: 2.5rem;
      font-size: 3rem;
      font-weight: bold;
      text-transform: capitalize;
      color: $purple-dark;
      position: relative;
    }

    .title::after {
      content: '';
      width: 25%;
      height: 0.1rem;
      background-color: $coral-dark;
      position: absolute;
      bottom: -1rem;
      left: 0;
    }

    .category {
      margin-bottom: 2rem;
    }

    .title,
    .category,
    .btn__details {
      opacity: 0;
      animation: fadeOut 1000ms ease-in-out 0ms forwards;
    }

    @include mq-tablet {
      width: 60% !important;
      top: 50%;
      transform: translateY(-50%);

      .title {
        font-size: 5rem !important;
      }

      .category {
        font-size: 1.75rem !important;
      }
    }
  }

  .slider__box {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;

    .slides__container {
      width: 100%;
      height: 100%;
      position: relative;

      @include display-row-start-col-center;

      .slide {
        width: 100%;
        height: 100%;
        position: relative;

        .image__box {
          width: 90%;
          height: 60%;

          img {
            height: 100%;
            width: auto;
            object-fit: cover;
          }
        }
      }

      .slide__work {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        animation: fadeOut 500ms ease-in-out forwards;
      }

      .slide__active {
        z-index: 1;

        .description__box {
          z-index: 1;
        }
      }
    }

    .slides__controls {
      width: 100%;
      height: 2rem;
      opacity: 0;

      @include display-row-center-col-center;

      display: none;
      position: absolute;
      bottom: 3%;
      left: 0;
      z-index: 2;
      animation: fadeOut 500ms ease-in-out forwards;

      .pagination {
        height: 100%;
        margin: 0 1.5rem;
        font-size: 0.6rem;
        color: $purple-light;

        @include display-row-center-col-center;

        .pag__current.slider__box {
          .slide {
            @include display-row-end-col-center;
          }
        }

        .pag__current,
        .pag__total {
          width: 1rem;
          height: 100%;
        }

        .pag__total {
          @include display-row-start-col-center;
        }

        .pag__progress {
          width: 2rem;
          height: 100%;
          margin: 0 0.25rem;
          position: relative;
        }

        .prog__track,
        .prog__thumb {
          width: 100%;
          height: 0.1rem;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(calc(50% - 0.1rem));
        }

        .prog__track {
          width: 2rem;
          background-color: rgba($purple-light, 0.5);
        }

        .prog__thumb {
          width: 0;
          background-color: rgba($purple-dark, 1);
        }
      }
    }

    .display__controls {
      display: flex;
    }

    @include mq-tablet {
      .slides__container {
        .slide {
          .image__box {
            width: 70%;
            height: 90%;
            margin-left: auto;
            margin-right: 0;
          }
        }
      }

      .slides__controls {
        bottom: 0;

        .pagination {
          font-size: 1rem;

          .pag__current,
          .pag__total {
            width: 1.5rem;
          }

          .pag__progress {
            width: 4rem;
          }

          .prog__track,
          .prog__thumb {
            height: 0.13rem;
            transform: translateY(calc(50% - 0.13rem));
          }

          .prog__track {
            width: 4rem;
          }
        }
      }
    }

    @include mq-desktop {
      .slides__container {
        .slide {
          .image__box {
            img {
              width: 100%;
              height: auto;
            }
          }
        }
      }
    }
  }

  @include mq-tablet {
    .section__container {
      padding-bottom: 5%;
    }
  }

  @include mq-desktop {
    .section__container {
      padding-bottom: 2% !important;
    }
  }
}

#works.works__animation {
  .slide__active {
    animation: fadeIn 500ms ease-in-out 250ms forwards;

    .image__box {
      animation: scaleTranslateImage 1000ms cubic-bezier(0.645, 0.045, 0.355, 1) 250ms forwards;
    }

    .description__box {
      .title {
        animation: translateToRight 750ms cubic-bezier(0.645, 0.045, 0.355, 1) 250ms forwards;
      }

      .category {
        animation: translateToRight 750ms cubic-bezier(0.645, 0.045, 0.355, 1) 500ms forwards;
      }

      .btn__details {
        animation: translateToRight 750ms cubic-bezier(0.645, 0.045, 0.355, 1) 750ms forwards;
      }
    }
  }

  .slides__controls {
    animation: translateToUp 750ms cubic-bezier(0.645, 0.045, 0.355, 1) 1000ms forwards;
  }
}

.modal__box-details {
  width: 100%;
  height: auto;
  background-color: $base;
  display: none;

  .modal__container {
    width: 100%;
    height: auto;
    margin: 0 auto;
    position: relative;
    opacity: 0;

    @include display-column-start-row-center;
  }

  .btn__back {
    position: absolute;
    left: 5%;
    z-index: 1;
  }

  .details__head,
  .details__body {
    width: 100%;
  }

  .details__head {
    height: 100vh;
    position: relative;

    .btn__back {
      top: 6%;
    }

    .image__box {
      img {
        height: 100%;
        width: auto;
      }
    }

    .description__box {
      width: 90%;
      left: 5%;

      .title,
      .category {
        opacity: 1;
        animation: fadeIn 250ms ease-in-out 0ms;
      }
    }

    .icon__mouse {
      left: 15%;
      bottom: 8%;
    }
  }

  .details__body {
    padding-bottom: 8rem;

    @include display-column-start-row-center;

    > * {
      margin-top: 4rem;
    }

    .body__group {
      width: 100%;
      padding: 0 5%;
      position: relative;

      .group__number {
        @include font-title;

        color: $yellow-medium;
        font-size: 1.1rem;
        position: absolute;
        top: 0.25rem;
        left: 5%;
      }

      .group__number::after {
        display: block;
        content: '';
        width: 100%;
        height: 2px;
        background-color: $yellow-medium;
        position: absolute;
        left: 60%;
        bottom: 10%;
      }

      .group__heading {
        margin-left: calc(5% + 2rem);
        margin-bottom: 2rem;
        font-size: 2rem;
      }

      &.group__buttons {
        @include display-row-center-col-center;

        .btn {
          margin: 0 0.5rem;
        }
      }

      .group__list {
        img {
          width: 2rem;
        }
      }

      .group__list.list-horizontal {
        li:last-child {
          padding-right: 0;
        }
      }

      .group__list.list-vertical {
        li:last-child {
          padding-bottom: 0;
        }
      }

      .image__box {
        width: 100%;
        height: auto !important;
        display: flex;
      }

      .circle {
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        margin-right: 1rem;
      }
    }

    .btn__back {
      bottom: 4rem;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  @include mq-tablet {
    .details__head {
      .image__box {
        width: 70%;
        height: 100%;
        margin-left: auto;
        margin-right: 0;
      }

      .icon__mouse {
        left: 15%;
        bottom: 5%;
      }
    }
  }
}

.modal__box-open {
  width: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 6;
}
